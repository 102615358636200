export const userManagementTranslationsDe = {
  user_management: 'Nutzerverwaltung',
  user_name: 'Nutzername / E-Mail',
  first_name: 'Vorname',
  last_name: 'Nachname',
  email: 'E-Mail',
  created_at: 'Erstellt am',
  user_id: 'Nutzer-ID',
  user_details: 'Nutzer-Info',
  sites: 'Anlagen',
  permissions: 'Berechtigungen',
  confirm_delete_user:
    'Wollen Sie den Nutzer "{user}" wirklich löschen? Dies kann nicht rückgängig gemacht werden!',
  add_user: 'Neuer Benutzer',
  user_management_permission_denied:
    'Sie haben nicht die notwendigen Berechtigungen um für diesen Kunden nutzer zu verwalten.',
  go_home: 'Zurück zur Übersicht',
  dashboard_read: 'Graphen anzeigen',
  dashboard_write: 'Graphen bearbeiten',
  scada_read: 'SCADA-Diagramme anzeigen',
  scada_write: 'SCADA-Diagramme bearbeiten',
  alarm_manager: 'Alarme verwalten',
  ditto_viewer: 'Ditto-Things anzeigen',
  ditto_operator: 'Ditto-Things bearbeiten',
  permission_ditto: 'Datenpunkte',
  permission_sites: 'Anlagen',
  permission_scada: 'Anlagen-Schema',
  permission_charts: 'Trends',
  permission_wmbus_keys: 'wM-Bus-Schlüssel',
  permission_alerts: 'Alarme',
  permission_analyses: 'Analysen',
  permission_read: 'Anzeigen',
  permission_write: 'Bearbeiten',
  permission_manage: 'Verwalten',
  roles_help_text: 'Diese Berechtigungen werden dem Nutzer bei allen ausgewählten Anlagen gewährt',
  sites_help_text: 'Bei diesen Anlagen werden alle gewählten Berechtigungen gewährt',
  invalid_feedback_mail: 'Es muss eine gültige Email-Adresse angegeben werden!',
  invalid_feedback_required: 'Dieses Feld darf nicht leer bleiben',
  required_field: 'Pflichtfeld',
  create_user_failed: 'Nutzer konnte nicht erstellt werden: {error}',
  create_user_success: 'Nutzer "{user}" wurde erfolgreich erstellt',
  delete_user_success: 'Nutzer "{name}" gelöscht',
  delete_user_failed: 'Nutzer konnte nicht gelöscht werden: {error}',
  edit_user: 'Nutzer bearbeiten',
  error_getting_sites: 'Anlagen konnten nicht geladen werden: {error}',
  error_loading_user_roles: 'Rollen für diesen Nutzer konnten nicht geladen werden: {error}',
  role_update_warning:
    'Es kann einige Minuten dauern bis Änderungen an den Berechtigungen übernommen werden.',
  role_update_success: 'Berechtigung "{name}" wurde geändert',
  role_update_fail: 'Berechtigung konnte nicht geändert werden: {error}',
  site_update_success: 'Anlagen-Berechtigungen erfolgreich geändert',
  site_update_fail: 'Anlagen-Berechtigungen konnten nicht geändert werden: {error}',
  error_getting_roles: 'Verfügbare Berechtigungen konnten nicht abgerufen werden: {error}',
  update_user_success: 'Nutzer "{name}" wurde aktualisiert',
  update_user_fail: 'Nutzer konnte nicht aktualisiert werden: {error}',
  created_but_error_sending_mail:
    'Der Nutzer wurde erstellt, aber es konnte keine Email zum Festlegen des Passworts gesendet werden: {error}',
  reset_password: 'Passwort zurücksetzen',
  confirm_reset_password:
    'Passwort für "{user}" zurücksetzen? (Der Nutzer erhält eine Email mit einem Link zur Passwortänderung)',
  password_reset_sent: 'Passwort zurückgesetzt für {user}',
  error_sending_mail: 'Passwort konnte nicht zurückgesetzt werden: {error}',
  new_user_mail_info:
    'Der neue Benutzer erhält im Anschluss eine E-Mail, um sich selber ein Passwort zu vergeben.',
  created_but_error_assigning_role:
    'Neuer Benutzer wurde angelegt, aber die Standard-Berechtigungen konnten nicht erteilt werden: {error}',
  user_management_role: 'Darf benutzer verwalten',
}
