export const userManagementTranslationsEn = {
  user_management: 'User management',
  user_name: 'Username / Email',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  created_at: 'Created at',
  user_id: 'User ID',
  user_details: 'User info',
  sites: 'Sites',
  permissions: 'Permissions',
  confirm_delete_user: 'Delete user "{user}"? This cannot be reversed!',
  add_user: 'New user',
  user_management_permission_denied:
    'You do not have the required permissions to manage users for this customer.',
  go_home: 'Go to dashboard',
  dashboard_read: 'View charts',
  dashboard_write: 'Edit charts',
  scada_read: 'View SCADA diagrams',
  scada_write: 'Edit SCADA diagrams',
  alarm_manager: 'Manage alerts',
  ditto_viewer: 'View ditto things',
  ditto_operator: 'Edit ditto things',
  permission_ditto: 'Datapoints',
  permission_scada: 'Plant Schematic',
  permission_charts: 'Trends',
  permission_wmbus_keys: 'wM-Bus Keys',
  permission_alerts: 'Alerts',
  permission_sites: 'Sites',
  permission_analyses: 'Analyses',
  permission_read: 'View',
  permission_write: 'Edit',
  permission_manage: 'Manage',
  roles_help_text: 'These permissions will be granted to the user on all selected sites',
  sites_help_text: 'Selected permissions are applied to all selected sites',
  invalid_feedback_mail: 'Please enter a valid email address',
  invalid_feedback_required: 'This field is required',
  required_field: 'Required fields',
  create_user_failed: 'Creation of new user failed: {error}',
  create_user_success: 'New user "{user}" created successfully',
  delete_user_success: 'User "{name}" deleted successfully',
  delete_user_failed: "User couldn't be deleted: {error}",
  edit_user: 'Edit user details',
  error_getting_sites: "Couldn't get available sites: {error}",
  error_loading_user_roles: 'Error while loading roles assigned to user: {error}',
  role_update_warning: 'Changes to permissions and sites may take a few minutes to take effect.',
  role_update_success: 'Permission "{name}" successfully updated',
  role_update_fail: 'Permissions could not be updated: {error}',
  site_update_success: 'Allowed sites successfully updated',
  site_update_fail: "Allowed sites couldn't be updated: {error}",
  error_getting_roles: "Couldn't retrieve available permissions: {error}",
  update_user_success: 'User "{name}" updated successfully',
  update_user_fail: 'Updating user failed: {error}',
  created_but_error_sending_mail:
    'User created successfully, but mail to set password could not be sent: {error}',
  reset_password: 'Reset password',
  confirm_reset_password:
    'Reset password for "{user}"? The user will receive an email with a link to update their password.',
  password_reset_sent: 'Password reset mail sent to {user}',
  error_sending_mail: 'Password reset mail could not be sent: {error}',
  new_user_mail_info: 'The new user will receive an email with a link to update their password.',
  created_but_error_assigning_role:
    'New user was created and password reset link was sent, but default permissions could not be assigned: {error}',
  user_management_role: 'Can manage users',
}
